<template>
  <div class="profile-header">
    <div>
      <el-image class="profile-image" :src="profileDetail.displayPhotoFilePath ?? defaultProfilePic" fit="fill"></el-image>
    </div>
    <div class="profile-name">{{ profileDetail.userName }}</div>
  </div>
</template>

<script>
export default {
  props: {
    profileDetail: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const defaultProfilePic = process.env.VUE_APP_DEFAULT_PIC_URL;

    return {
      defaultProfilePic,
    };
  },
};
</script>
