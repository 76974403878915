<template>
  <ProfileHeader v-if="profile" :profileDetail="profile" />

  <el-row class="profile-container">
    <el-col :span="0" :sm="6">
      <ProfileNavMenu />
    </el-col>
    <el-col :span="24" :sm="0">
      <div
        class="sub-header fs-16 fm-montserrat center-text to-upper"
        style="padding-bottom: 20px;"
      >{{ $route.name }}</div>
    </el-col>
    <el-col :span="24" :sm="{ span: 16 }" v-if="profile">
      <router-view :profileDetail="profile" :getProfile="getProfile"></router-view>
    </el-col>
  </el-row>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import profileServices from '@/services/profile-service';
import ProfileHeader from '@/components/Profile/ProfileHeader.vue';
import ProfileNavMenu from '@/components/Profile/ProfileNavMenu.vue';

export default {
  name: 'Profile Layout',
  setup() {
    const store = useStore();
    const profile = ref(null);

    const getProfile = async () => {
      profile.value = await profileServices.getProfilebyProfileId(store.state.auth.loginProfile.profileId);
    };

    onMounted(async () => {
      getProfile();
    });

    return {
      profile,
      getProfile,
    };
  },
  components: {
    ProfileHeader,
    ProfileNavMenu,
  },
};
</script>
