<template>
  <div class="custom-tab">
    <div
      v-for="tab in tabs"
      class="tab-item"
      :key="tab"
      :class="tabName === tab.tabName ? 'selected' : ''"
      @click="tabName = tab.tabName"
    >
      <div>{{ tab.tabLabel }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /*
    object: {
      tabLabel: string,
      tabName: String
    }
    */
    tabs: {
      type: Array,
      required: true,
    },
    modelValue: String,
  },
  emits: ['update:modelValue'],
  watch: {
    tabName(newTabName) {
      this.$emit('update:modelValue', newTabName);
    },
  },
  data() {
    return {
      tabName: this.modelValue,
    };
  },
};
</script>
