<template>
  <ProfileHeader v-if="profile" :profileDetail="profile" />

  <el-row class="profile-container">
    <el-col :span="0" :sm="6">
      <ProfileNavMenu :isLoginUserProfile="false" />
    </el-col>
    <el-col :span="24" :sm="0">
      <div
        style="padding-bottom: 20px;"
      ><CustomTab v-model="activeTabName" :tabs="tabOptions" /></div>
    </el-col>
    <el-col :span="24" :sm="{ span: 16 }">
      <router-view></router-view>
    </el-col>
  </el-row>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import profileServices from '@/services/profile-service';
import ProfileHeader from '@/components/Profile/ProfileHeader.vue';
import ProfileNavMenu from '@/components/Profile/ProfileNavMenu.vue';
import { useStore } from 'vuex';
import CustomTab from '@/components/CustomTab.vue';

export default {
  name: 'Listings',
  setup() {
    const profile = ref(null);
    const route = useRoute();
    const store = useStore();
    const router = useRouter();
    const activeTabName = ref('listings');
    const tabOptions = ref([
      {
        tabName: 'listings',
        tabLabel: 'Listings',
      },
      {
        tabName: 'reviews',
        tabLabel: 'Reviews',
      },
    ]);

    onMounted(async () => {
      if (store.state.auth.loginProfile && route.params.id === store.state.auth.loginProfile.userId) {
        router.push('/profile/listings');
        return;
      }

      profile.value = await profileServices.getProfilebyUserId(route.params.id);
    });

    watch(
      () => activeTabName.value,
      () => {
        router.push(`/user/${route.params.id}/${activeTabName.value}`);
      },
    );

    return {
      profile,
      activeTabName,
      tabOptions,
    };
  },
  components: {
    ProfileHeader,
    ProfileNavMenu,
    CustomTab,
  },
};
</script>
