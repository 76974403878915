<template>
  <div>
    <el-skeleton animated>
      <template #template>
        <div class="center-text license-image-container">
          <el-skeleton-item variant="image" style="width: 100%; height: 100%;" />
        </div>
        <div class="license-name">
          <el-skeleton-item variant="text" style="width: 50%" />
        </div>
      </template>
    </el-skeleton>
  </div>
</template>
