<template>
  <div class="product-card">
    <div class="product-card-wrapper">
        <div class="product-img-wrapper">
          <img class="product-img" style="object-fit: fill; position: absolute" :src="artistDetail.artistDisplayPhotoFilePath" />
        </div>
        <div style="text-align: left; line-height: 1.7; padding-top: 20px; padding-bottom: 20px" class="sub-label-light fs-16">
          <div style="text-align:center"><b>{{ artistDetail.artistName }}</b></div>
        </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    artistDetail: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
  },
};
</script>
