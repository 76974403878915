<template>
  <el-row v-if="bannerProducts">
    <el-col :span="24" v-if="bannerProducts.length > 0">
      <Carousel class="home-carousel">
        <Slide v-for="product in bannerProducts" :key="product">
          <div class="carousel__item">
            <div class="carousel-item-overlay">
              <div class="color-background"></div>
              <img class="image-background" :src="product.images[0].productImageUrl" />
            </div>
            <div class="carousel-item-container">
              <el-row :gutter="40">
                <el-col :span="10">
                  <div class="image-container">
                    <img :src="product.images[0].productImageUrl" />
                  </div>
                </el-col>
                <el-col :span="14">
                  <div class="detail-container">
                    <div class="info-container">
                      <div>A secure, convenient marketplace for buying, selling and trading exclusive collectibles.</div>
                    </div>
                    <div class="button-container desktop">
                      <el-button class="custom-btn primary-btn gray">Shop Now</el-button>
                      <el-button class="custom-btn primary-btn gray">Start Selling</el-button>
                    </div>
                  </div>
                </el-col>
                <el-col class="mobile-only">
                  <div class="detail-container">
                    <div class="button-container mobile">
                      <el-button class="custom-btn primary-btn gray">Shop Now</el-button>
                      <el-button class="custom-btn primary-btn gray">Start Selling</el-button>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </Slide>

        <template #addons>
          <Pagination />
        </template>
      </Carousel>
    </el-col>
  </el-row>
  <el-row>
    <el-col
      :span="22"
      :offset="1"
      :md="{ span: 18, offset: 3 }"
      style="margin-bottom: 15px; margin-top: 50px"
      v-if="auctionEndSoonProducts.length > 0"
    >
      <div class="home-listing-container">
        <div class="sub-header left-text" style="padding-bottom: 20px;">Auctions Ending Soon</div>
        <div>
          <el-row v-if="!auctionEndSoonProductsLoading" :gutter="20">
            <el-col v-for="product in auctionEndSoonProducts" :key="product" :span="6" :xs="12">
              <div>
                <div class="sub-header center-text fs-16" style="padding-bottom: 10px;">
                  <Countdown :endDate="product.auctionEndDate" />
                </div>
                <router-link :to="`/product/${product.productId}`">
                  <ProductCard :productDetail="product" />
                </router-link>
              </div>
            </el-col>
          </el-row>
          <el-row v-else :gutter="20">
            <el-col v-for="index in 4" :key="index" :span="6" :xs="12">
              <ProductCardLoader />
            </el-col>
          </el-row>
        </div>
      </div>
    </el-col>
  </el-row>

  <el-row>
    <el-col
      :span="22"
      :offset="1"
      :md="{ span: 18, offset: 3 }"
      style="margin-bottom: 15px; margin-top: 50px"
    >
      <div class="home-listing-container">
        <div class="sub-header left-text" style="padding-bottom: 40px;">Popular Listing</div>
        <div>
          <el-row :gutter="20" v-if="popularProducts">
            <el-col v-for="product in popularProducts" :key="product" :span="6" :xs="12">
              <router-link :to="`/product/${product.productId}`">
                <ProductCard :productDetail="product" />
              </router-link>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-else>
            <el-col v-for="index in 4" :key="index" :span="6" :xs="12">
              <ProductCardLoader />
            </el-col>
          </el-row>
        </div>
      </div>
    </el-col>
  </el-row>

  <el-row>
    <el-col
      :span="22"
      :offset="1"
      :md="{ span: 18, offset: 3 }"
      style="margin-bottom: 15px; margin-top: 50px"
    >
      <div class="home-listing-container">
        <div class="sub-header left-text" style="padding-bottom: 40px;">Featured Artist</div>
        <div >
          <el-row :gutter="20" v-if="artists">
            <el-col v-for="artist in artists" :key="artist" :span="6" :xs="12">
                <ArtistCard :artistDetail="artist" />
            </el-col>
          </el-row>
          <el-row :gutter="20" v-else>
            <el-col v-for="index in 4" :key="index" :span="6" :xs="12">
              <ProductCardLoader />
            </el-col>
          </el-row>
        </div>
      </div>
    </el-col>
  </el-row>

  <el-row>
    <el-col
      :span="22"
      :offset="1"
      :md="{ span: 18, offset: 3 }"
      style="margin-bottom: 15px; margin-top: 50px"
    >
      <div class="home-listing-container">
        <div class="sub-header left-text" style="padding-bottom: 20px;">License</div>
        <div>
          <el-row v-if="licenses.length > 0" :gutter="20">
            <el-col v-for="license in licenses" :key="license" :span="6" :xs="12">
              <router-link :to="`/products/license/${license.licenseId}`">
                <div class="center-text license-image-container">
                  <img :src="license.displayImage" />
                </div>
                <div class="license-name">{{ license.name }}</div>
              </router-link>
            </el-col>
          </el-row>
          <el-row v-else :gutter="20">
            <el-col v-for="index in 4" :key="index" :span="6" :xs="12">
              <LicenseCardLoader />
            </el-col>
          </el-row>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { Carousel, Pagination, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import productServices from '@/services/product-service';
import ProductCard from '@/components/Product/ProductCard.vue';
import ArtistCard from '@/components/Product/ArtistCard.vue';
import LicenseCardLoader from '@/components/License/LicenseCardLoader.vue';
import ProductCardLoader from '@/components/Product/ProductCardLoader.vue';
import Countdown from '@/components/Product/Countdown.vue';

export default {
  name: 'Home',
  components: {
    Carousel,
    Slide,
    Pagination,
    ProductCard,
    ArtistCard,
    LicenseCardLoader,
    ProductCardLoader,
    Countdown,
  },
  computed: {
    limitedArtists() {
      return this.artists.slice(0, 4);
    }
  },
  setup() {
    const bannerProducts = ref(null);
    const auctionEndSoonProducts = ref([]);
    const artists = ref([]);
    const auctionEndSoonProductsLoading = ref(true);
    const popularProducts = ref(null);
    const store = useStore();
    const licenses = computed(() => store.getters['layout/popularLicenses']);

    const getBannerProducts = async () => {
      bannerProducts.value = await productServices.getBannerProducts();
    };

    const getAuctionEndSoonProducts = () => {
      productServices
        .getAuctionEndSoonProducts()
        .then(
          (data) => {
            auctionEndSoonProducts.value = data;
          },
          () => {
            auctionEndSoonProducts.value = [];
          },
        )
        .finally(() => {
          auctionEndSoonProductsLoading.value = false;
        });
    };

    const getPopularProducts = async () => {
      popularProducts.value = await productServices.getPopularProducts();
    };

    const getArtists = async () => {
      artists.value = await productServices.getArtist();
      artists.value = artists.value.slice(0, 8);
    };

    onMounted(() => {
      getArtists();
      getBannerProducts();
      getPopularProducts();
      getAuctionEndSoonProducts();
    });

    return {
      licenses,
      bannerProducts,
      auctionEndSoonProducts,
      auctionEndSoonProductsLoading,
      popularProducts,
      artists
    };
  },
};
</script>
