<template>
  <div class="affix-container">
    <el-affix target=".affix-container" :offset="120" :z-index="1">
      <el-menu id="profile-nav" :router="true" :default-active="$route.path">
        <template v-if="isLoginUserProfile">
          <el-menu-item @click="$router.push('/profile/listings')" index="/profile/listings">
            <template #title>Listings</template>
          </el-menu-item>
          <el-menu-item
            @click="$router.push('/profile/transactions')"
            index="/profile/transactions"
          >
            <template #title>Transactions</template>
          </el-menu-item>
          <el-menu-item @click="$router.push('/profile/likes')" index="/profile/likes">
            <template #title>Likes</template>
          </el-menu-item>
          <el-menu-item @click="$router.push(`/profile/reviews`)" index="/profile/reviews">
            <template #title>Reviews</template>
          </el-menu-item>
          <el-menu-item @click="$router.push('/profile/settings')" index="/profile/settings">
            <template #title>Settings</template>
          </el-menu-item>
        </template>
        <template v-else>
          <el-menu-item
            @click="$router.push(`/user/${$route.params.id}/listings`)"
            :index="`/user/${$route.params.id}/listings`"
          >
            <template #title>Listings</template>
          </el-menu-item>
          <el-menu-item
            @click="$router.push(`/user/${$route.params.id}/reviews`)"
            :index="`/user/${$route.params.id}/reviews`"
          >
            <template #title>Reviews</template>
          </el-menu-item>
        </template>
      </el-menu>
    </el-affix>
  </div>
</template>

<script>
export default {
  props: {
    isLoginUserProfile: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.affix-container {
  height: 100%;
}
</style>
